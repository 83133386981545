import React, { useState } from "react"
import { Box, Button, Card, Flex, Link, Text } from "theme-ui"
import { graphql } from "gatsby"
import flat from 'flat'
import fileDownload from "js-file-download"

import FileLayout from "../layouts/file-layout"
import { getEditedFileString, getLangLocalFileUrl } from "../util"
import DiffView from "../components/diff-view"
import { DownloadButton, ExternalLink } from "../components/download-buttons"
import Editable from "../components/editable"

const TABLE_COLUMNS = ['300px', 'auto']

const EnglishEditJson = ({ pageContext: { site, lang, fileLocalPath, filePathInRepo, ...context }, data }) => {
  const [edited, setEdited] = useState({})
  const [diffView, setDiffView] = useState()
  const rawFile = data.rawFile.content
  const jsonFile = flat(JSON.parse(rawFile))
  const editedCount = Object.values(edited).filter(msg => msg).length

  function downloadEdited() {
    const fileName = filePathInRepo.split('/').slice(-1)[0]
    fileDownload(getEditedFileString(jsonFile, edited), fileName)
  }

  return <FileLayout site={site} lang={lang} {...context}>
    <Flex sx={{justifyContent: 'flex-end'}}>
      <Flex sx={{
        justifyContent: 'space-around',
        p: 3,
        pt: 2
      }}>
        <Box sx={{mr: 5}}>
          <a href={getLangLocalFileUrl(site, lang, filePathInRepo)} download>
            <DownloadButton variant="outline">
              Download original
            </DownloadButton>
          </a>
          <br/>
          <ExternalLink href={`https://github.com/${site.repo}/blob/${site.branch}/${filePathInRepo}`}>
            View on Github
          </ExternalLink>
        </Box>
        <Box sx={{mr: 4}}>
          <DownloadButton onClick={() => downloadEdited()} disabled={editedCount === 0}>
            Download edited
          </DownloadButton>
          <br />
          <Link sx={{
            cursor: editedCount > 0 ? 'pointer' : 'default',
            opacity: editedCount > 0 ? '1.0' : '0.2'
          }} onClick={() => {
            if (editedCount === 0) {
              return;
            }
            setDiffView(<DiffView
              originalFile={rawFile}
              editedFileString={getEditedFileString(jsonFile, edited)}
            />)
          }}>
            View diff ({editedCount})
          </Link>
        </Box>
      </Flex>
    </Flex>
    {diffView && <Box sx={{position: 'absolute', top: '80px', left: '40px', right: '40px', zIndex: '10'}}>
      <Card sx={{ mb: '40px'}}>
        <Flex sx={{justifyContent: 'flex-end'}}>
          <Button sx={{py: '10px'}} onClick={() => setDiffView(null)}>Close</Button>
        </Flex>
        <Flex sx={{justifyContent: 'space-between', fontSize: 5}}>
          <Text>Current {lang} file</Text>
          <Text>Edited {lang} file</Text>
        </Flex>
        {diffView}
      </Card>
    </Box>}
    <Box
      as="table"
      sx={{
        width: '100%',
        borderBottom: "1px solid",
        borderTop: "1px solid",
        borderColor: "muted",
        borderSpacing: "0",
        px: 2,
        py: 1,
      }}
    >
        <Box as="thead" sx={{fontWeight: 'bold'}}>
          <tr>
            <Text as="td" sx={{width: TABLE_COLUMNS[0]}}>id</Text>
            <Text as="td">text</Text>
          </tr>
        </Box>
      </Box>
    <Box
      sx={{
        maxHeight: '70vh',
        overflow: "auto",
        borderBottom: "1px solid",
        borderColor: "muted",
        "&::-webkit-scrollbar": {
          width: "5px",
          minWidth: "5px"
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: "foreground",
          borderRadius: "small"
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "muted",
          borderRadius: "small"
        }
      }}
    >
      <Box as="table" sx={{
        borderSpacing: "0",
        width: "100%",
        "& > tr:nth-child(even)": {
          backgroundColor: 'rgba(200,200,200,0.2)'
        },
        '& > tr td': {
          py: '10px'
        }
      }}>
        {Object.entries(jsonFile).map(([key, text]) =>
          <Box as="tr" sx={{ "td": { py: "10px", mr: "16px" } }}>
            <Box as="td" sx={{
              width: TABLE_COLUMNS[0],
              maxWidth: TABLE_COLUMNS[0],
              overflow: "hidden",
              position: "relative",
              pl: 2,
              "&:hover": { overflow: "visible", "& > div": { backgroundColor: "rgba(255,255,255,0.9)" } },
            }}>
              <Text sx={{ position: "absolute", top: "50%", transform: "translateY(-50%)", pr: "10px" }}>{key}</Text>
            </Box>
            <Box as="td" sx={{ width: TABLE_COLUMNS[1], pl: "10px" }}>
              <Editable
                value={text}
                editedValue={edited[key]}
                onEdit={(value) => setEdited({...edited, [key]:value})}
              />
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  </FileLayout>
}

export default EnglishEditJson

export const query = graphql`
  query EnglishEditJsonQuery($fileLocalPath: String) {
    rawFile(relativePath: {eq: $fileLocalPath}) {
      content
    }
  }  
`

